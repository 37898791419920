import React, { useEffect, useState } from "react";
import {
  Container,
  Content,
  Title,
  Group,
  Block,
  BlockImg,
  BlockTitle,
} from "./style.js";
import { ModalProvider } from "styled-react-modal";
import { useNavigate } from "react-router-dom";
import home1 from "../../public/images/home/1.jpg";
import home2 from "../../public/images/home/2.jpg";
import home3 from "../../public/images/home/3.jpg";
import home4 from "../../public/images/home/4.jpg";
import home5 from "../../public/images/home/5.jpg";
import home6 from "../../public/images/home/6.jpg";
import home7 from "../../public/images/home/7.jpg";
import home8 from "../../public/images/home/8.jpg";
import home9 from "../../public/images/home/9.jpg";
import home10 from "../../public/images/home/10.jpg";
import home11 from "../../public/images/home/11.jpg";
import home12 from "../../public/images/home/12.jpg";
import home13 from "../../public/images/home/13.jpg";
import home14 from "../../public/images/home/14.jpg";
import home15 from "../../public/images/home/15.jpg";
import home16 from "../../public/images/home/16.jpg";
import home17 from "../../public/images/home/17.jpg";
import home18 from "../../public/images/home/18.jpg";
import home19 from "../../public/images/home/19.jpg";
import home20 from "../../public/images/home/20.jpg";
import userpic from "../../public/images/user.png";

import { Helmet } from 'react-helmet';
// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

const Home = () => {
  let navigate = useNavigate();
  const [index, setIndex] = useState();
  const images1 = [home1, home2, home3, home4, home13, home14, home15, home16];
  const images2 = [home5, home6, home7, home8, home17, home18, home19, home20];
  const images3 = [home9, home10, home11, home12];

  let url = new URL(window.location.href);
  let searchParams = new URLSearchParams(url.search);
  let level = searchParams.get("level");

  const [user, setUser] = useState(null);
  const [showSignOut, setShowSignOut] = useState(false);
  const firebaseConfig = {
    apiKey: "AIzaSyDad4JckQLNMALW3RpKnTq6X3KuPP90Ej0",
    authDomain: "polavoice-test.firebaseapp.com",
    projectId: "polavoice-test",
    storageBucket: "polavoice-test.appspot.com",
    messagingSenderId: "163776593379",
    appId: "1:163776593379:web:fc91cac693491786abb4d6",
    measurementId: "G-GX4XSS66XR"
  };
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);

  //是否有登入
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // 使用者已登入，更新狀態
        setUser(user);
        console.log(user);
      } else {
        // 使用者未登入，將狀態設為null並重定向到登入頁面
        setUser(null);
        navigate('/Login'); // 假設你的登入頁面路徑是'/login'
      }
    });
  }, [navigate]);

  // 登出函數
  const handleSignOut = () => {
    signOut(auth).then(() => {
      alert('登出成功！');
      navigate('/Login');
    }).catch((error) => {
      // 處理錯誤
      console.error("登出錯誤", error);
    });
  };

  const desc1 = ["慶生會", "爬山趣", "去超市買東西", "去動物園", "森林體驗", "農場體驗", "森林冒險", "運動會"];
  const desc2 = ["大賣場購物", "外送員送貨", "小小偵探", "大掃除", "海邊度假", "圖書館閱讀", "沙灘玩樂", "上學日"];
  const desc3 = ["露營趣", "藏寶遊戲", "聖誕節包裝禮物", "親子遊樂趣"];

  const Blocks = [];
  const levelData = {
    1: { start: 0, end: 8, level: 1, baseIndex: 1 },
    2: { start: 0, end: 8, level: 2, baseIndex: 1 },
    3: { start: 0, end: 4, level: 3, baseIndex: 1 },
  };
  
  const createBlock = (i, level, baseIndex) => {
    const navigatePath = `/listen?level=${level}&index1=${i}&index2=${baseIndex + (i - levelData[level].start) * 6}&speed=1&voice=female`;
    console.log(i);
    const getBlockContent = () => {
      switch (level) {
        case '1':
          return (
            <>
              <BlockImg url={images1[i]} />
              <BlockTitle>{desc1[i]}</BlockTitle>
            </>
          );
        case '2':
          return (
            <>
              <BlockImg url={images2[i]} />
              <BlockTitle>{desc2[i]}</BlockTitle>
            </>
          );
        case '3':
          return (
            <>
              <BlockImg url={images3[i]} />
              <BlockTitle>{desc3[i]}</BlockTitle>
            </>
          );
        default:
          return null;
      }
    };
    return (
      <Block key={i} onClick={() => { setIndex(i); navigate(navigatePath); }}>
        {getBlockContent()}
      </Block>
    );
  };
  
  if (levelData[level]) {
    for (let i = levelData[level].start; i < levelData[level].end; i++) {
      Blocks.push(createBlock(i, level, levelData[level].baseIndex));
    }
  }

  return (
    <ModalProvider>
      <Helmet>
        <title>層級{level}</title>
      </Helmet>
      <div style={{position:'fixed', right:'15px', top:'15px'}}>
          {user && (
            <span>
              <img src={userpic} alt='使用者' onClick={() => setShowSignOut(!showSignOut)} style={{width:'35px', height:'35px',cursor: 'pointer'}}/>
              {showSignOut && (
                <div style={{ position:'fixed', right:'1%',marginTop:'20px', backgroundColor:'#e5e5e5', padding:'1% 2%', borderRadius:'10px'}}>
                  <h4 style={{marginBottom:'10px'}}>歡迎：{user.email}登入使用</h4>
                  <button style={{padding:'2px 123px',cursor:'pointer'}} onClick={handleSignOut}>登出</button>
                </div>
              )}
            </span>
          )}
      </div>
     <Container>
        <Content>
          <Title>你今天想學點什麼呢？</Title>
          <Group>{Blocks}</Group>
        </Content>
    </Container>
    </ModalProvider>
  );
};

export default Home;

