import React, { useState,useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import settings from "../../public/images/settings.png";
import userpic from "../../public/images/user.png";
import wrong from "../../public/images/close2.png";
import correct from "../../public/images/level1.png";
import change from "../../public/images/replay.png";
import './StorySet.css'
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from "firebase/auth";
import { collection, getDoc, getDocs, query, where, doc, addDoc, writeBatch,limit, setDoc, deleteDoc,startAfter} from 'firebase/firestore';
import { getFirestore,updateDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';

function StorySet() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showSignOut, setShowSignOut] = useState(false);
  const [storyid, setStoryid] = useState(null);
  const [currentLevel, setCurrentLevel] = useState('level1');

  // 初始化 Firebase 應用
  const firebaseConfig = {
    apiKey: "AIzaSyDad4JckQLNMALW3RpKnTq6X3KuPP90Ej0",
    authDomain: "polavoice-test.firebaseapp.com",
    projectId: "polavoice-test",
    storageBucket: "polavoice-test.appspot.com",
    messagingSenderId: "163776593379",
    appId: "1:163776593379:web:fc91cac693491786abb4d6",
    measurementId: "G-GX4XSS66XR"
  };
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);

  //是否有登入
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // 使用者已登入，更新狀態
        setUser(user);
        console.log(user);
      } else {
        // 使用者未登入，將狀態設為null並重定向到登入頁面
        setUser(null);
        navigate('/Login'); // 假設你的登入頁面路徑是'/login'
      }
    });
  }, [navigate]);

  // 登出函數
  const handleSignOut = () => {
    signOut(auth).then(() => {
      alert('登出成功！');
      navigate('/Login');
    }).catch((error) => {
      // 處理錯誤
      console.error("登出錯誤", error);
    });
  };

  // 初始化或頁面加載時讀取localStorage中儲存的等級
  useEffect(() => {
    const savedLevel = localStorage.getItem('currentLevel');
    if (savedLevel) {
      setCurrentLevel(savedLevel);
    }
  }, []);

  //讀取資料庫
  const [teachData, setTeachData] = useState([]);
  const [editableFields, setEditableFields] = useState({}); 
  {/*useEffect(() => {
    const fetchTeachData = async (parentPath, childName) => {
      let documents = [];
  
      // 獲取當前層級的所有文檔快照
      const currentSnapshot = await getDocs(collection(db, ...parentPath));
      for (const docSnapshot of currentSnapshot.docs) {
        const data = docSnapshot.data();
        const docId = docSnapshot.id;
        const docPath = [...parentPath, docId];
  
        // 構造當前文檔的資料和路徑
        const docData = {
          id: docId,
          path: docPath.join('/'),
          data: data,
        };
  
        // 嘗試獲取子集合的文檔
        const childCollectionRef = collection(db, ...docPath, childName);
        const childSnapshot = await getDocs(childCollectionRef);
        if (!childSnapshot.empty) {
          // 子集合存在，則進一步遞迴獲取子集合中的文檔資料
          const childrenData = await fetchTeachData([...docPath, childName], childName);
          docData.children = childrenData;
        }
  
        documents.push(docData);
      }
  
      return documents;
    }; // 依賴於currentLevel，當其變化時重新獲取教材資料
  
    fetchTeachData(['AIGenerate'],'UnRead').then(teachData => {
      setTeachData(teachData);
      console.log(teachData);
    }).catch(error => {
      console.error("Error fetching data: ", error);
    });
  }, []);*/}

  const [data, setData] = useState(null);
  //獲取第一個集合資料
  useEffect(() => {
    const fetchTeachData = async (startAfterDoc = null) => {
      // 指定到特定集合的引用，並使用limit來僅查詢第一個文檔
      let unreadQuery = query(collection(db, "AIGenerate", currentLevel, "UnRead"),limit(1));

      if (startAfterDoc) {
        unreadQuery = query(unreadQuery, startAfter(startAfterDoc));
      }
      const querySnapshot = await getDocs(unreadQuery);

  
      if (!querySnapshot.empty) {
        // 取得第一個文檔的Snapshot
        const docSnapshot = querySnapshot.docs[0];
        const data = docSnapshot.data();
        if(data.check === null){
          let initialEditableFields = {};
  
          // 處理數據，這裡假設數據是物件形式
          Object.keys(data).forEach((key) => {
            if (typeof data[key] === 'object' && data[key] !== null) {
              Object.keys(data[key]).forEach((innerKey) => {
                initialEditableFields[`${docSnapshot.id}-${key}-${innerKey}`] = data[key][innerKey];
              });
            } else {
              initialEditableFields[`${docSnapshot.id}-${key}`] = data[key];
            }
          });
          const documentRef = doc(db, "AIGenerate", currentLevel, "UnRead", docSnapshot.id);
          updateDoc(documentRef, {
              check: true
          }).then(() => {
              console.log('check 更新成功');
          }).catch(error => {
              console.error('更新 check 欄位失敗:', error);
          });
    
          // 在這裡直接設置 firstData 狀態
          setStoryid(docSnapshot.id);
          setEditableFields(initialEditableFields);
          setData(data);
          console.log(data.check);
          console.log(initialEditableFields);
          console.log(currentLevel);
          console.log(data);
          console.log(storyid);
    
          return data;
        }else{
          console.log("return");
          return fetchTeachData(docSnapshot);
        }
      } else {
        console.log("No documents found in 'UnRead'.");
      }
    };
  
    fetchTeachData().then().catch(error => {
      console.error("Error fetching data: ", error);
    });
  }, [currentLevel,db]);  


  //文檔更改紀錄
  const handleInputChange = (event, parentId, questionIndex) => {
    const { name, value } = event.target; // 獲取欄位名稱和新的值
    // 更新狀態
    setEditableFields(prevFields => ({
      ...prevFields,
      [`${parentId}-${questionIndex}-${name}`]: value
    }));
    console.log(editableFields)
  };  

  //修改完提交
  const handleSubmit = async (e) => {
    e.preventDefault(); // 阻止表單的默認提交行為
  
    // 假設我們直接使用 editableFields 作為要提交的數據
    try {
      // 添加一個新文檔到 Modify 集合
      const docRef = await addDoc(collection(db, "AIGenerate", currentLevel, "Modify"), editableFields);
      console.log("故事成功存入，文檔ID:", docRef.id);
      alert("故事成功存入！");

      const docId = storyid;
      // 假設已經知道要刪除的故事的level和docId，這裡為了簡單起見直接使用"level1"，實際應該根據實際情況獲取
      const docRefUnRead = doc(db, "AIGenerate", currentLevel, "UnRead", docId);
  
      // 先檢查該文檔是否存在
      const docSnapshot = await getDoc(docRefUnRead);
        // 存在則先將其資料移動到Reject集合
        await setDoc(doc(db, "AIGenerate", currentLevel, "Read", docId), data);
        console.log(docId);
  
        // 然後從UnRead集合中刪除
        await deleteDoc(docRefUnRead);
  
        console.log("故事已從UnRead移動到Read");
        window.location.reload();
      // 假設 docId 是您要移動的文檔的 ID
      //const formData = new FormData(e.target);
      //const parentId = formData.get("parentId");
      //const docId = storyid;

      // 讀取 UnRead 中的文檔
      //const collectionRef = collection(db, "AIGenerate", currentLevel, "UnRead");
      //const docRefUnRead = doc(collectionRef, docRef.id);
      //const docSnapshot = await getDoc(docRefUnRead);
        //const docData = docSnapshot.data();

        // 將資料保存到 Read 集合
        //const docRefRead = doc(db, "AIGenerate", currentLevel, "Read",data.id);
        //await setDoc(docRefRead, data);

        // 從 UnRead 刪除該文檔
        //await deleteDoc(data);
        //console.log("資料已從 UnRead 移動到 Read");
        //window.location.reload()
    } catch (error) {
      console.error("存入數據失敗：", error);
      alert("存入故事失敗！");
    }
  };

  //刪除故事
  const DeleteStory = async () => {
    try {
      const docId = storyid;
      // 假設已經知道要刪除的故事的level和docId，這裡為了簡單起見直接使用"level1"，實際應該根據實際情況獲取
      const docRefUnRead = doc(db, "AIGenerate", currentLevel, "UnRead", docId);
  
      // 先檢查該文檔是否存在
      const docSnapshot = await getDoc(docRefUnRead);
      console.log(docId);
        // 存在則先將其資料移動到Reject集合
        await setDoc(doc(db, "AIGenerate", currentLevel, "Reject", docId), data);
  
        // 然後從UnRead集合中刪除
        await deleteDoc(docRefUnRead);
  
        console.log("故事已從UnRead移動到Reject");
        alert("故事成功刪除！");
        window.location.reload();
    } catch (error) {
      console.error("刪除故事時發生錯誤", error);
      alert("刪除故事失敗！");
    }
  };
  


  //累積篇數
  const levelMapping = {
    level1: '初級',
    level2: '中級',
    level3: '中高級',
    level4: '高級',
  };

  const [levelsCount, setLevelsCount] = useState({
    level1: 0,
    level2: 0,
    level3: 0,
    level4: 0,
  });

  useEffect(() => {
    const fetchCounts = async () => {
      const counts = { level1: 0, level2: 0, level3: 0, level4: 0 };
  
      for (const [levelKey, levelName] of Object.entries(levelMapping)) {
        const q = query(collection(db, "AIGenerate", levelKey, "UnRead"));
        const querySnapshot = await getDocs(q);
        counts[levelKey] = querySnapshot.docs.length;
      }
  
      setLevelsCount(counts);
    };
  
    fetchCounts().catch(console.error);
  }, []);

  // 更新當前選擇的等級
  const handleLevelChange = (e) => {
    const newLevel = e.target.value;
    setCurrentLevel(newLevel);
    localStorage.setItem('currentLevel', newLevel); // 儲存到localStorage
  };


return (
    <div style={{ padding: '20px'}}>
      <Helmet>
        <title>故事生成頁面</title>
      </Helmet>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <select style={{cursor: 'pointer', width: '200px', height: '30px', fontSize: '16px'}} onChange={handleLevelChange} value={currentLevel}>
          {Object.entries(levelsCount).map(([levelKey, count]) => (
            <option key={levelKey} value={levelKey}>{`${levelMapping[levelKey]} - 已累積${count}篇`}</option>
          ))}
        </select>

        <div>
          {/*<span style={{ cursor: 'pointer', marginRight: '20px' }} onClick={() => navigate(`/History`)}>
            <img src={settings} alt='設置' style={{width:'35px', height:'35px'}}/>
          </span>*/}
          {user && (
            <span>
              <img src={userpic} alt='使用者' onClick={() => setShowSignOut(!showSignOut)} style={{width:'35px', height:'35px',cursor: 'pointer'}}/>
              {showSignOut && (
                <div style={{ position:'fixed', right:'1%',marginTop:'20px', backgroundColor:'#e5e5e5', padding:'1% 2%', borderRadius:'10px'}}>
                  <h4 style={{marginBottom:'10px'}}>歡迎：{user.email}登入使用</h4>
                  <button style={{padding:'2px 123px',cursor:'pointer'}} onClick={handleSignOut}>登出</button>
                </div>
              )}
            </span>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '46%', border: '1px solid blue', padding: '10px 20px' }}>
            <p style={{textAlign:'center', fontWeight:'bold', letterSpacing:'8px', fontSize:'24px', borderBottom:'1px solid lightgray', padding:'10px', marginBottom:'10px'}}>自動生成教材內容</p>            
            {/*<div key={`parent-${index}`}>*/}
            {
              data
              && (
                <div>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>描述：</strong><span style={{fontSize:'16px'}}>{data[0].description}</span></p>                  
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>問題1：</strong><span style={{fontSize:'16px'}}>{data[0].question}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>正確答案：</strong><span style={{fontSize:'16px'}}>{data[0].correctAnswer}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>錯誤答案1：</strong><span style={{fontSize:'16px'}}>{data[0].wrongAnswer1}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>錯誤答案2：</strong><span style={{fontSize:'16px'}}>{data[0].wrongAnswer2}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px',marginBottom:"40px"}}><strong style={{fontSize:'18px'}}>錯誤答案3：</strong><span style={{fontSize:'16px'}}>{data[0].wrongAnswer3}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>問題2：</strong><span style={{fontSize:'16px'}}>{data[1].question}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>正確答案：</strong><span style={{fontSize:'16px'}}>{data[1].correctAnswer}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>錯誤答案1：</strong><span style={{fontSize:'16px'}}>{data[1].wrongAnswer1}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>錯誤答案2：</strong><span style={{fontSize:'16px'}}>{data[1].wrongAnswer2}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px',marginBottom:"40px"}}><strong style={{fontSize:'18px'}}>錯誤答案3：</strong><span style={{fontSize:'16px'}}>{data[1].wrongAnswer3}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>問題3：</strong><span style={{fontSize:'16px'}}>{data[2].question}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>正確答案：</strong><span style={{fontSize:'16px'}}>{data[2].correctAnswer}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>錯誤答案1：</strong><span style={{fontSize:'16px'}}>{data[2].wrongAnswer1}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>錯誤答案2：</strong><span style={{fontSize:'16px'}}>{data[2].wrongAnswer2}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px',marginBottom:"40px"}}><strong style={{fontSize:'18px'}}>錯誤答案3：</strong><span style={{fontSize:'16px'}}>{data[2].wrongAnswer3}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>問題4：</strong><span style={{fontSize:'16px'}}>{data[3].question}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>正確答案：</strong><span style={{fontSize:'16px'}}>{data[3].correctAnswer}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>錯誤答案1：</strong><span style={{fontSize:'16px'}}>{data[3].wrongAnswer1}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>錯誤答案2：</strong><span style={{fontSize:'16px'}}>{data[3].wrongAnswer2}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px',marginBottom:"40px"}}><strong style={{fontSize:'18px'}}>錯誤答案3：</strong><span style={{fontSize:'16px'}}>{data[3].wrongAnswer3}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>問題5：</strong><span style={{fontSize:'16px'}}>{data[4].question}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>正確答案：</strong><span style={{fontSize:'16px'}}>{data[4].correctAnswer}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>錯誤答案1：</strong><span style={{fontSize:'16px'}}>{data[4].wrongAnswer1}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>錯誤答案2：</strong><span style={{fontSize:'16px'}}>{data[4].wrongAnswer2}</span></p>
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>錯誤答案3：</strong><span style={{fontSize:'16px'}}>{data[4].wrongAnswer3}</span></p>
                </div>
              )
            }
        </div>
        <div style={{ width: '46%', border: '1px solid orange', padding: '10px 20px' }}>
            <p style={{textAlign:'center', fontWeight:'bold', letterSpacing:'8px', fontSize:'24px', borderBottom:'1px solid lightgray', padding:'10px', marginBottom:'10px'}}>老師針對教材內容修改</p>
            {
              data && (
                <div>
                  {/* 描述只顯示一次 */}
                  {Object.values(data).slice(0, 1).map((childItem, description) => (
                  <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>描述：</strong>
                    <textarea
                      style={{fontSize:'16px', width:'100%',height:'auto',paddingBottom:'5px',overflowY: 'hidden'}}
                      name={`description`}
                      value={editableFields[`${storyid}-0-description`] || ""}
                      onChange={(e) => handleInputChange(e, storyid, description)}
                    />
                  </p>))}

                  {/* 循環構建問題和答案的輸入框 */}
                  {Object.values(data).slice(0, 5).map((childItem, questionIndex) => (
                    <div key={`question-${questionIndex}`}>
                      <input type="hidden" name="parentId" value={data.id} />
                      <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>問題{questionIndex+1}：</strong>
                        <textarea
                          style={{fontSize:'16px', width:'100%',height:'auto',paddingBottom:'5px',overflowY: 'hidden'}}
                          name={`question`}
                          value={editableFields[`${storyid}-${questionIndex}-question`] || ""}
                          onChange={(e) => handleInputChange(e, storyid, questionIndex)}
                        />
                      </p>
                      <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>正確答案：</strong>
                        <textarea
                          style={{fontSize:'16px', width:'100%',height:'auto',paddingBottom:'5px',overflowY: 'hidden'}}
                          name={`correctAnswer`}
                          value={editableFields[`${storyid}-${questionIndex}-correctAnswer`] || ""}
                          onChange={(e) => handleInputChange(e, storyid, questionIndex)}
                        />
                      </p>
                      <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>錯誤答案1：</strong>
                        <textarea
                          style={{fontSize:'16px', width:'100%',height:'auto',paddingBottom:'5px',overflowY: 'hidden'}}
                          name={`wrongAnswer1`}
                          value={editableFields[`${storyid}-${questionIndex}-wrongAnswer1`] || ""}
                          onChange={(e) => handleInputChange(e, storyid, questionIndex)}
                        />
                      </p>
                      <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>錯誤答案2：</strong>
                        <textarea
                          style={{fontSize:'16px', width:'100%',height:'auto',paddingBottom:'5px',overflowY: 'hidden'}}
                          name={`wrongAnswer2`}
                          value={editableFields[`${storyid}-${questionIndex}-wrongAnswer2`] || ""}
                          onChange={(e) => handleInputChange(e, storyid, questionIndex)}
                        />
                      </p>
                      <p style={{marginTop:'15px', letterSpacing:'3px'}}><strong style={{fontSize:'18px'}}>錯誤答案3：</strong>
                        <textarea
                          style={{fontSize:'16px', width:'100%',height:'auto',paddingBottom:'5px',overflowY: 'hidden'}}
                          name={`wrongAnswer3`}
                          value={editableFields[`${storyid}-${questionIndex}-wrongAnswer3`] || ""}
                          onChange={(e) => handleInputChange(e, storyid, questionIndex)}
                        />
                      </p>
                    </div>
                  ))}
                </div>
              )
            }
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <button type="button" style={{cursor: 'pointer', marginRight: '20px', fontSize:'18px', padding:'10px 20px', borderRadius:'38px', border:'1px solid  black', background:'#e76f51', color:'white' }} onClick={() => DeleteStory()}><img src={wrong} alt='刪除' style={{width:'20px', height:'20px', marginRight:'10px', position:'relative', top:'2px' }}/>刪除故事</button>
        <button 
          style={{cursor: 'pointer', marginRight: '10px', fontSize:'18px', padding:'10px 20px', borderRadius:'38px', border:'1px solid  black', background:'rgb(58, 183, 149)', color:'white' }}
          type='summit'>
            <img src={correct} alt='確認' style={{width:'20px', height:'20px', marginRight:'10px', position:'relative', top:'2px' }}/>
            確認送出
        </button>
      </div>
      </form>
    </div>
  );
}

export default StorySet;
