import React, { useEffect, useState } from "react";
import {
  Container,
  Content,
  ContentStatus,
  ContentTitle,
  ContentDesc,
  StatusIcon,
} from "./style";
import NavBar from "../../public/components/NavBar";
import { useNavigate } from "react-router-dom";
import icon from "../../public/images/icon_white_1.png";
import { information } from "./information";

const Desc = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  let url = new URL(window.location.href);
  let searchParams = new URLSearchParams(url.search);
  let index = searchParams.get("index");

  let timer = setTimeout(() => {
    setCount(count + 1);
  }, 1000);

  useEffect(() => {
    setTitle(information.title[index]);
    setText(information.text[index]);
  }, []);

  useEffect(() => {
    if (count === 3) {
      clearInterval(timer);
      navigate(`/listen?index=${index}`);
    }
  }, [count]);

  return (
    <Container>
      <NavBar></NavBar>
      <Content>
        <ContentStatus>
          <StatusIcon src={icon} width={34} />
          初級
        </ContentStatus>
        <ContentTitle>{title}</ContentTitle>
        <ContentDesc>{text}</ContentDesc>
      </Content>
    </Container>
  );
};

export default Desc;
