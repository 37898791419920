// UserContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Firebase的配置。請用您自己的配置替換這裡的內容。
const firebaseConfig = {
    apiKey: "AIzaSyDad4JckQLNMALW3RpKnTq6X3KuPP90Ej0",
    authDomain: "polavoice-test.firebaseapp.com",
    projectId: "polavoice-test",
    storageBucket: "polavoice-test.appspot.com",
    messagingSenderId: "163776593379",
    appId: "1:163776593379:web:fc91cac693491786abb4d6",
    measurementId: "G-GX4XSS66XR"
  };

// 初始化Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, [auth]);

  return (
    <UserContext.Provider value={currentUser}>
      {children}
    </UserContext.Provider>
  );
};

export const useCurrentUser = () => {
  return useContext(UserContext);
};
