import React from "react";
import ReactGA from 'react-ga4';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Choose from "./components/Choose/Choose";
import Desc from "./components/Desc/Desc";
import Home from "./components/Home/Home";
import Test from "./components/Home/Test";
import Level from "./components/Level/Level";
import Listen from "./components/Listen/Listen";
import Login from "./components/Login/GoogleLogin";
import PreQuestion from "./components/PreQuestion/PreQuestion";
import Question from "./components/Question/Question";
import Report from "./components/Report/Report";
import StorySet from "./components/StorySet/StorySet";
import { UserProvider } from './googleAnalytics';
import PageViewTracker from "./pagetrackview";

ReactGA.initialize("G-K71LJ4PKHR");
/*
function PageViewTracker() {
  const location = useLocation();
  const user = useCurrentUser(); // 假设 useCurrentUser 是一个自定义的Hook，用于获取当前用户

  useEffect(() => {
    const analytics = getAnalytics();
    const userId = user ? user.uid : '未登入';

    // 在GA4中设置用户ID
    window.gtag('set', 'user_properties', {
      member: userId // 你的自定义参数
    });

    // 发送页面视图事件
    window.gtag('event', 'page_view', {
      page_path: location.pathname,
      page_title: document.title,
      member: userId
    });
    console.log("UserID：" + userId);

    // 如果你有其他事件要追踪，也可以在这里添加
  }, [location, user]);

  return null;
}*/


function App() {
  return (
    <UserProvider>
      <Router>
        <Report />
        <Routes key={uuidv4()} >
          <Route  path="/" element={<><PageViewTracker /><Level /></>} />
          <Route path="/Home" element={<><PageViewTracker /><Home /></>} />
          <Route path="/description" element={<><PageViewTracker /><Desc /></>} />
          <Route path="/listen" element={<><PageViewTracker /><Listen /></>} />
          <Route path="/test" element={<><PageViewTracker /><Test/></>} />
          <Route path="/choose" element={<><PageViewTracker /><Choose /></>} />
          <Route path="/question" element={<><PageViewTracker /><Question /></>} />
          <Route path="/preQuestion" element={<><PageViewTracker /><PreQuestion /></>} />
          <Route path="/StorySet" element={<><PageViewTracker /><StorySet /></>} />
          <Route path="/Login" element={<><PageViewTracker /><Login /></>} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;