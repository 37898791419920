import React, { useState, useEffect, useRef} from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  ImageContainer,
  Image,
  ImageContainer2,
  Image2,
  Feedback,
  ConfirmBtn,
  SpeakingBtn,
  BtnContainer,
  ButtonIcon,
  StageGroup,
  Stagetext,
  Stagebtn,
} from "./style";
import styled from "styled-components";

import {
  SpeechConfig,
  ResultReason,
} from "microsoft-cognitiveservices-speech-sdk";
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import NavBar from "../../public/components/NavBar";
import Check from "../../public/images/check_circle.png";
import { description } from "../description";
// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import ReactGA from 'react-ga';
import left from "../../public/images/Right.png"
import right from "../../public/images/Left.png"
import userpic from "../../public/images/user.png";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

const Question = () => {
  let player
  const [stage, setStage] = useState(0);
  const [click, setClick] = useState(false);
  const [correct, setCorrect] = useState(3);
  const [send, setSend] = useState(true);
  const [pick, setPick] = useState([false, false, false, false]);
  const [select, setSelect] = useState();

  const [speaking, setSpeaking] = useState(true);
  const [BtnText, setBtnText] = useState("開始");
  const [CheckBtn, setCheckBtn] = useState("確認答案");
  const [BtnColor, setBtnColor] = useState("#3AB795");
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(0);
  const [key, setKey] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [audioSrc, setAudioSrc] = useState('');
  const audioRef = useRef(null);
  const [isPlaybackFinished, setIsPlaybackFinished] = useState(false);
  const [currentlevel, setCurrentlevel] = useState('');
  const [ImgShow, setImgShow] = useState("關閉");

  const [imgPrompt, setImgPrompt] = useState([]);
  const [gif, setGif] = useState("");


  const [img1, setImg1] = useState("");
  const [img2, setImg2] = useState("");
  const [img3, setImg3] = useState("");
  const [img4, setImg4] = useState("");

  const firebaseConfig = {
    apiKey: "AIzaSyDad4JckQLNMALW3RpKnTq6X3KuPP90Ej0",
    authDomain: "polavoice-test.firebaseapp.com",
    projectId: "polavoice-test",
    storageBucket: "polavoice-test.appspot.com",
    messagingSenderId: "163776593379",
    appId: "1:163776593379:web:fc91cac693491786abb4d6",
    measurementId: "G-GX4XSS66XR"
  };
  // 初始化 Firebase 應用
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const navigate = useNavigate();

  let url = new URL(window.location.href);
  let searchParams = new URLSearchParams(url.search);
  //const searchParams = new URLSearchParams(window.location.search);
  let index1 = searchParams.get("index1");
  let index2 = searchParams.get("index2");
  let level = parseInt(searchParams.get("level"))-1;
  let voice = searchParams.get("voice");
  let speed = parseFloat(searchParams.get("speed"));
  let arg = JSON.parse(decodeURIComponent(searchParams.get("arg")))
  let yessound = new Audio(require("../../public/images/success.wav"))
  let nosound = new Audio(require("../../public/images/failed.wav"))
  let stage1 = searchParams.get("stage1");

  const speechConfig = SpeechConfig.fromSubscription(
    "4caafccbb4ac4a90a919756a83a6018f",
    "eastus"
  );
  //speechConfig.speechSynthesisLanguage = "zh-TW";
  //speechConfig.endpointId = "640b9fe2-1d09-41a8-8f5c-acb57590e347";
  speechConfig.speechSynthesisVoiceName = "zh-TW-HsiaoYuNeural";
  //speechConfig.speechSynthesisVoiceName = "HannahNeural";
  player = new SpeechSDK.SpeakerAudioDestination();
  var audioConfig  = SpeechSDK.AudioConfig.fromSpeakerOutput(player);
  const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);

  useEffect(() => {
    if(level == 0){
      document.title = "層級"+(level+1)+"-"+(parseInt(index1)+1)+"-"+stage1;
      setCurrentlevel('level1');
    }else if(level == 1){
      document.title = "層級"+(level+1)+"-"+(parseInt(index1)+1)+"-"+stage1;
      setCurrentlevel('level2');
    }else if(level == 2){
      document.title = "層級"+(level+1)+"-"+(parseInt(index1)+1)+"-"+stage1;
      setCurrentlevel('level3');
    }
  }, []);

  const [user, setUser] = useState(null);
  const [showSignOut, setShowSignOut] = useState(false);
  //是否有登入
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // 使用者已登入，更新狀態
        setUser(user);
        console.log(user);
      } else {
        // 使用者未登入，將狀態設為null並重定向到登入頁面
        setUser(null);
        navigate('/Login'); // 假設你的登入頁面路徑是'/login'
      }
    });
  }, [navigate]);

  // 登出函數
  const handleSignOut = () => {
    signOut(auth).then(() => {
      alert('登出成功！');
      navigate('/Login');
    }).catch((error) => {
      // 處理錯誤
      console.error("登出錯誤", error);
    });
  };

  //Gif動畫
  useEffect(() => {
    console.log("correct "+ correct);
    if(correct == 0){
      setGif(require("../../public/images/no.gif"))
      nosound.play()
    }else if(correct == 1){
      setGif(require("../../public/images/yes.gif"))
      yessound.play()
    }else if(correct == 2){
      setGif(require("../../public/images/no1.gif"))
      nosound.play()
    }
    setTimeout(() => {
      setSend(false);
    },2000);
  }, [correct]);

  //Pic讀取
  useEffect(() => {
    console.log(speed)
    console.log(voice)
    console.log("stage "+ stage);

    const loadImage = async () => {
      console.log("index2 "+index2)
      const imageModule = await import("../../public/images/level"+(level+1)+"/pic"+(parseInt(index2))+".png");
      setImageUrl(imageModule.default);
      if(ImgShow == "顯示"){
        const pic1 = await import("../../public/images/level"+(level+1)+"/pic"+(parseInt(index2))+"-1"+".png");
        setImg1(pic1.default);
        const pic2 = await import("../../public/images/level"+(level+1)+"/pic"+(parseInt(index2))+"-2"+".png");
        setImg2(pic2.default);
        const pic3 = await import("../../public/images/level"+(level+1)+"/pic"+(parseInt(index2))+"-3"+".png");
        setImg3(pic3.default);
        const pic4 = await import("../../public/images/level"+(level+1)+"/pic"+(parseInt(index2))+"-4"+".png");
        setImg4(pic4.default);
      }else if (ImgShow == "關閉"){
        const pic1 = await import("../../public/images/question.png");
        setImg1(pic1.default);
        setImg2(pic1.default);
        setImg3(pic1.default);
        setImg4(pic1.default);
      }
    };
    loadImage();
    //reset();
  }, [stage,ImgShow]);


  useEffect(() => {
    console.log(pick);
  }, [pick]);

  //Pic點擊
  const imageClick = (index) => {
    if (BtnText == "開始" || BtnText == "播放中..." || ImgShow == '關閉'){
      return
    }
    let temp = pick.fill(false);
    temp[index] = true;
    setSelect(index);
    setPick(temp);
    setClick(true);
    prepare(AnswerDescription);
    console.log(pick);
  };

  //播放鍵
  const handleClick = () => {
    if(questionDescription == null){
      return
    }
    if (BtnText == "繼續" && audioRef.current) {
      audioRef.current.play();
      setSpeaking(true);
      setBtnText("播放中...");
    }else if(BtnText == "播放中..." && audioRef.current) {
      audioRef.current.pause();
      setSpeaking(false);
      setBtnText("繼續");
    }else if(BtnText == "開始" || BtnText == "再聽一次"){
      setBtnText("播放中...");
      setSpeaking(true);
      setBtnColor("#ffb703");
      handleTextToSpeech(questionDescription);
    }
    if(speaking){
      console.log(player.currentTime)
      player.mute();
      return
    }
  };

  const reset = () => {
    console.log("finish")
    setBtnText("開始");
    setBtnColor("#3AB795");
    setSpeaking(false);
    //setClick(false);
    setSend(false);
    //setPick([false, false, false, false]);
  };

  //語速調整
  const calculateValue = () => {
    if (speed >= 0.1 && speed <= 1) {
      // 当speed在0.1到1之间时，使用线性插值计算value
      // y = mx + b 形式，其中m是斜率，b是y轴截距
      var m = (0 - (-90)) / (1 - 0.1); // 计算斜率
      var b = -90 - m * 0.1; // 计算y轴截距
      if(voice == "male" || voice == "female2"){
        return m * speed*0.75 + b +"%";
      }else{
        return m * speed + b +"%";
      }
    } else if (speed > 1 && speed <= 2) {
      // 当speed在1到2之间时，使用另一个线性插值计算value
      var m = (100 - 0) / (2 - 1); // 计算斜率
      var b = 0 - m * 1; // 计算y轴截距
      if(voice == "male" || voice == "female2"){
        return m * speed*0.75 + b +"%";
      }else{
        return m * speed + b +"%";
      }
    } else {
      // 如果speed不在0.1到2的范围内，返回null或者抛出错误
      return null; // 或者 throw new Error('Speed out of range');
    }
  }

  const handleTextToSpeech = () => {
    let question = questionDescription
    console.log(question)
    const voiceType = voice == "female" ? 'zh-TW-HsiaoYuNeural' : (voice == "female2" ? "fr-FR-VivienneMultilingualNeural" : 'zh-TW-YunJheNeural'); 
    const speedType = calculateValue() 

    const ssml = `<speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts="http://www.w3.org/2001/mstts" xml:lang='zh-TW'>
      <voice name='${voiceType}'>
      <mstts:silence  type="Leading-exact" value="2000ms" style="excited"/>
        <prosody rate='${speedType}'>${question}</prosody>
      </voice>
    </speak>`;
    synthesizer.speakSsmlAsync(
      ssml,
      (result) => {
        if (result.reason === ResultReason.SynthesizingAudioCompleted) {
          console.log("Speech synthesis succeeded");
          player.mute()
          if(audioSrc == ""){
            const audioBlob = new Blob([result.audioData], { type: 'audio/mp3' });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioSrc(audioUrl);
          }else{
            audioRef.current.play();
          }
        } else {
          console.error("Speech synthesis failed: " + result.errorDetails);
        } 
        synthesizer.close();
      },
      (error) => {
        console.error("An error occurred while synthesizing speech: " + error);
        synthesizer.close();
      }
    );
  };

  /*player.onAudioStart = (_) => {
    console.log("playback started");
    console.log(player.currentTime)
  };
  player.onAudioEnd = (_) => {
    console.log("playback finished");
    console.log(player.currentTime)
    setTimeout(reset, 900);
  };*/
  const onAudioEnd = () => {
    console.log("playback finished");
    setBtnText("再聽一次");
    setBtnColor("#3AB795");
    setSpeaking(false);
    //setClick(false);
    //setSend(false);
    //setPick([false, false, false, false]);
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
    }
    /*setIsPlaybackFinished(true); // 設置狀態為播放結束
    if (isPlaybackFinished) {
      reset();
      setIsPlaybackFinished(false); // 重置狀態
    }*/
  };

  const View = styled.div``;
  const ImageView = styled.img``;

  //firebase
  const [teachData, setTeachData] = useState([]);

  /*useEffect(() => {
    const fetchTeachData = async (parentPath, childName) => {
      let documents = [];
  
      // 獲取當前層級的所有文檔快照
      const currentSnapshot = await getDocs(collection(db, ...parentPath));
      for (const docSnapshot of currentSnapshot.docs) {
        const data = docSnapshot.data();
        const docId = docSnapshot.id;
        const docPath = [...parentPath, docId];
  
        // 構造當前文檔的資料和路徑
        const docData = {
          id: docId,
          path: docPath.join('/'),
          data: data,
        };
  
        // 嘗試獲取子集合的文檔
        const childCollectionRef = collection(db, ...docPath, childName);
        const childSnapshot = await getDocs(childCollectionRef);
        if (!childSnapshot.empty) {
          // 子集合存在，則進一步遞迴獲取子集合中的文檔資料
          const childrenData = await fetchTeachData([...docPath, childName], childName);
          docData.children = childrenData;
        }
  
        documents.push(docData);
      }
  
      return documents;
    };
  
    fetchTeachData(['Teach'],'1').then(teachData => {
      setTeachData(teachData);
      console.log(teachData);
      console.log(currentQuestionIndex,questionDescKey,index1,AnswerDescKey);
      console.log("L"+level);
    }).catch(error => {
      console.error("Error fetching data: ", error);
    });
  }, []);*/

  useEffect(() => {
    const fetchDataByLevel = async (currentlevel) => {
      let documents = [];
      const levelCollectionRef = collection(db, "Teach", currentlevel, "1");
      const snapshot = await getDocs(levelCollectionRef);
      snapshot.docs.forEach(doc => {
        documents.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      return documents;
    };

    fetchDataByLevel(currentlevel)
      .then(teachData => {
        setTeachData(teachData);
        console.log(teachData);
        console.log(currentlevel);
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
      });
  }, [currentlevel]); 

  


  // 狀態來追踪當前問題的索引，初始為 Q1
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(parseInt(index1) === 0 ? parseInt(index2) - 1 : parseInt(index1) === 1 ? parseInt(index2) - 7 : parseInt(index1) === 2 ? parseInt(index2) - 13 : parseInt(index1) === 3 ? parseInt(index2) - 19 : parseInt(index1) === 4 ? parseInt(index2) - 25 : parseInt(index1) === 5 ? parseInt(index2) - 31 : parseInt(index1) === 6 ? parseInt(index2) - 37 : parseInt(index2) - 43);
  //const groupData = teachData[level]?.children[level == 0 ? index1 : level == 1 ? index1-4 : index1-8]?.data?.group;
  const groupData = teachData[index1]?.data?.group;
  // 根據當前問題索引構建問題描述的訪問路徑
  const questionKey = `Q${currentQuestionIndex}`;
  const questionDescKey = `${questionKey}Desc`;
  const questionDescription = groupData?.[questionKey]?.[questionDescKey];

  //Ans
  const [currentAnswerIndex, setCurrentAnswerIndex] = useState(parseInt(index1) === 0 ? parseInt(index2) - 1 : parseInt(index1) === 1 ? parseInt(index2) - 7 : parseInt(index1) === 2 ? parseInt(index2) - 13 : parseInt(index1) === 3 ? parseInt(index2) - 19 : parseInt(index1) === 4 ? parseInt(index2) - 25 : parseInt(index1) === 5 ? parseInt(index2) - 31 : parseInt(index1) === 6 ? parseInt(index2) - 37 : parseInt(index2) - 43);
  // 根據當前問題索引構建問題描述的訪問路徑
  const AnswerKey = `Q${currentAnswerIndex}`;
  const AnswerDescKey = `A${currentAnswerIndex}`;
  const AnswerDescription = groupData?.[AnswerKey]?.[AnswerDescKey];

  const prepare = async() => {
    setCorrectAnswerIndex(AnswerDescription);
    console.log("D:"+AnswerDescription)
    setKey((prevKey) => prevKey + 1);
  }

  return (
    <Container>
      <NavBar index1={index1} index2={index2}/>
      <div style={{position:'fixed',left:'15px' ,top:'15px' ,display:'flex'}}>
          {user && (
            <span>
              <img src={userpic} alt='使用者' onClick={() => setShowSignOut(!showSignOut)} style={{width:'35px', height:'35px',cursor: 'pointer'}}/>
              {showSignOut && (
                <div style={{ position:'fixed', left:'15px',marginTop:'10px', backgroundColor:'#e5e5e5', padding:'1% 1%', borderRadius:'10px'}}>
                  <h4 style={{marginBottom:'10px'}}>歡迎：{user.email}登入使用</h4>
                  <button style={{padding:'2px 123px',cursor:'pointer'}} onClick={handleSignOut}>登出</button>
                </div>
              )}
            </span>
          )}
          <div style={{marginLeft:'20px', marginTop:'7px', }}>
            <button style={{padding:'2px', cursor:'pointer'}} onClick={() => setImgShow("顯示")}>開啟圖片</button>
            <button style={{marginLeft:'10px', padding:'2px', cursor:'pointer'}} onClick={() => setImgShow("關閉")}>隱藏圖片</button>
          </div>
      </div>
      <StageGroup>
        <Stagebtn onClick={() => {
          if(stage1!=1){
            window.location.href =`/question?level=${level+1}&index1=${index1}&index2=${parseInt(index2) - 1}&speed=${speed}&voice=${voice}&stage1=${parseInt(stage1)-1}`;
          }
        }}>
          <img src={left} alt='LEFT' style={{width:"15px"}} />
        </Stagebtn>
        <Stagetext>第{stage1}題</Stagetext>
        <Stagebtn onClick={() => {
          if(stage1!=5){
            window.location.href =`/question?level=${level+1}&index1=${index1}&index2=${parseInt(index2) + 1}&speed=${speed}&voice=${voice}&stage1=${parseInt(stage1)+1}`;
          }
        }}>
          <img src={right} alt='Right' style={{width:"15px"}} />
        </Stagebtn>
      </StageGroup>
      <Feedback send={send}>
        <img src={gif} alt='' />
      </Feedback>
      <ImageContainer2>
        <Image2 url={imageUrl} />
      </ImageContainer2>
      <ImageContainer> 
        <div style={{width:"100%",height:"70%",display:"flex", flexDirection: "column", justifyContent: "center", alignItems: "center",cursor:"pointer"}}>
          <Image onClick={() => imageClick(0) } pick={pick[0]} url={img1}></Image>
        </div>
        <div style={{width:"100%",height:"70%",display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",cursor:"pointer"}}>
          <Image onClick={() => imageClick(1)} pick={pick[1]} url={img2}></Image>
        </div>
        <div style={{width:"100%",height:"70%",display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",cursor:"pointer"}}>
          <Image onClick={() => imageClick(2)} pick={pick[2]} url={img3}></Image>
        </div>
        <div style={{width:"100%",height:"70%",display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",cursor:"pointer"}}>
          <Image onClick={() => imageClick(3)} pick={pick[3]} url={img4}></Image>        
        </div>
      </ImageContainer>

      <audio ref={audioRef} src={audioSrc} controls autoPlay onEnded={onAudioEnd}/><br></br>
     
      <BtnContainer>
        <SpeakingBtn BtnColor={BtnColor} onClick={handleClick}>
          {BtnText}
        </SpeakingBtn>
        <ConfirmBtn
          click={click}
          onClick={() => {
            setSend(true);
            if(CheckBtn == "確認答案"){
              select === correctAnswerIndex
              ? setCorrect(1)
              : (correct === 2 ? setCorrect(0):setCorrect(2));
            }
             if(select === correctAnswerIndex && parseInt(stage1) != 5) {
              //setCorrect(1);
              setCheckBtn("下一題");
              /*setTimeout(() => {
                window.location.href =`/question?level=${level+1}&index1=${index1}&index2=${parseInt(index2) + 1}&speed=${speed}&voice=${voice}&stage1=${parseInt(stage1)+1}`;
                setStage(stage+1);
                setCorrect(3);
              }, 2000); // 延遲2000毫秒（2秒）*/
             }
             if(CheckBtn == "下一題"){
              window.location.href =`/question?level=${level+1}&index1=${index1}&index2=${parseInt(index2) + 1}&speed=${speed}&voice=${voice}&stage1=${parseInt(stage1)+1}`;
             }
          }}
          >
          <ButtonIcon src={Check} width={30}></ButtonIcon>
          {CheckBtn}
        </ConfirmBtn>
      </BtnContainer>
    </Container>
  );
};

export default Question;
