import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fdfaed;
`;

export const Content = styled.div`
  width: 100vw;
  height: 88vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StatusIcon = styled.img`
  margin: 0px 6px;
  width: ${(props) => props.width};
  src: url(${(props) => props.src});
`;

export const ContentStatus = styled.div`
  width: 160px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  background-color: #ffb703;
  border: 2px solid #3b3b3b;
  color: white;
  border-radius: 23px;
  text-align: center;
  line-height: 64px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  letter-spacing: 0.15em;
`;

export const ContentTitle = styled.div`
  height: 88px;
  margin-bottom: 30px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 800;
  font-size: 62.8571px;
  line-height: 88px;
  letter-spacing: 0.25em;
  color: #3ab795;
`;

export const ContentDesc = styled.div`
  margin-bottom: 100px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 31px;
  text-align: center;
  letter-spacing: 0.15em;
  color: #3b3b3b;
`;
