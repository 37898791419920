import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import TagManager from 'react-gtm-module';

import ReactGA from 'react-ga';
ReactGA.initialize('G-K71LJ4PKHR');

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

const tagManagerArgs = {
    gtmId: 'GTM-PKTW53M4'  // 替換為你的GTM容器ID
};
TagManager.initialize(tagManagerArgs);
