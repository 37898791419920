import React, { useEffect, useState } from "react";
import { Configuration, OpenAIApi } from "openai";
import axios from "axios";
import { ModalProvider } from "styled-react-modal";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { prompt } from "./string";
import StateManager from './StateManager';
const configuration = new Configuration({
  apiKey: "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",
});
const openai = new OpenAIApi(configuration);




const Input = styled.input`
padding: 0.5em;
margin: 0.5em;
color: palevioletred;
background: papayawhip;
border: none;
border-radius: 3px;
` ;

const Button = styled.button`
  padding: 0.5em;
  margin: 0.5em;
  color: white;
  background: palevioletred;
  border: none;
  border-radius: 3px;
`;


const Text = styled.p`
  font-size: 1.5em;
  text-align: left; // 將這行改為 left
  width: 80%;
  height: 80%;
  margin: 0 auto;
  white-space: pre-wrap; // 新增這行
  overflow: auto; // 新增這行
  height: 80%; // 新增這行，設定高度
`;




const Test = () => {


const state = {
  "state1":{
      "name":"死不認錯",
      "state":"state1",
      "statePrompt":"現在的情境是：你是掉棒的學生Solly，我們班級參加大隊接力比賽，因為你掉棒失誤了，使得原來領先的名次落後了，賽後，其他同學以及我不斷指責你，但是你絕對不認錯，彼此爆發衝突。",
      "condPrompt":"如果你覺得我沒有在指責，而是講道理來說服你，你在回覆的句子前先加1，如果我是在指責。你就在追富的句子前加上0",
      "count":0
    },
  "state2":{
      "name":"願意聽",
      "state":"state2",
      "statePrompt":"現在的情境是：你是掉棒的學生Solly，我們班級參加大隊接力比賽，因為你掉棒失誤了，使得原來領先的名次落後了，賽後，其他同學以及我不斷指責你，現在你願意聽，但是還是不認錯。",
      "condPrompt":"如果你覺得我的話讓你很開心，你在回覆的句子前先加1，如果讓你不開心。你就在追富的句子前加上0",
      "count":0
    },
  "state3":{
      "name":"道歉",
      "state":"state3",
      "statePrompt":"現在的情境是：你是掉棒的學生Solly，我們班級參加大隊接力比賽，因為你掉棒失誤了，使得原來領先的名次落後了，賽後，其他同學以及我不斷指責你，你開始有點不好意思。",
      "condPrompt":"",
      "count":0
     
    }
}

  const [inputValue, setInputValue] = useState("");
  const [text, setText] = useState("");

  const [fsmStates, setFsmStates] = useState(state.state1); // 新增一個狀態來儲存有限狀態機的狀態
  const [newState, setNewState] = useState(""); // 新增一個狀態來儲存使用者輸入的新狀態

  const handleNewStateChange = (e) => {
    setNewState(e.target.value); // 更新新狀態的值
  };

  const handleAddState = () => {
    setFsmStates((prevStates) => [...prevStates, newState]); // 將新狀態加入到有限狀態機的狀態列表中
    setNewState(""); // 清空輸入框
  };



  useEffect(() => {
  

    //query();
  }, []);

  useEffect(() => {
  
  
  }, [text]);



  const query1 = (input, statePrompt, condPrompt) => {
    console.log("input " + input)
    console.log("statePrompt " + statePrompt)
    console.log("condPrompt " + condPrompt)
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            model: "gpt-4",
            messages: [
              { role: "user", content: prompt.system },
              { role: "user", content: statePrompt },
              { role: "user", content: prompt.except },
              { role: "user", content: input },
              { role: "user", content: condPrompt },
      
            ],
            "temperature":0.9,
          },
          {
            headers: {
              Authorization:
                "Bearer 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",
              "Content-Type": "application/json",
            },
          }
        );
        const ret = response.data.choices[0].message.content;
        console.log(response)
    
        resolve(ret);
      } catch (error) {
        console.log(error.response)
        console.log("fffffff")
        console.log(error)
        reject(error);
      }
    });
  };
  let navigate = useNavigate();
  let url = new URL(window.location.href);
  let searchParams = new URLSearchParams(url.search);
  let level = searchParams.get("level");



  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = async () => {
    
    console.log(fsmStates)
    
  
    if(fsmStates.state == "state1"){
      const ret = await query1(inputValue,fsmStates.statePrompt, fsmStates.condPrompt);
      //console.log(ret[0]);
      if(ret[0] == 1){
        fsmStates.count = fsmStates.count + 1
      }
      if(fsmStates.count == 1){
        setFsmStates(state.state2)
      }
      setInputValue("")
      setText(text+"\n"+"我: "+inputValue+"\n"+ "Solly: "+ret);
    }else  if(fsmStates.state == "state2"){
   
      
      const ret = await query1(inputValue,fsmStates.statePrompt, fsmStates.condPrompt);
      //console.log(ret[0]);
      if(ret[0] == 1){
        fsmStates.count = fsmStates.count + 1
      }
      if(fsmStates.count == 1){
        setFsmStates(state.state3)
      }
      setInputValue("")
      setText(text+"\n"+"我: "+inputValue+"\n"+ "Solly: "+ret);
      
    
    }else  if(fsmStates.state == "state3"){      
      const ret = await query1(inputValue,fsmStates.statePrompt, fsmStates.condPrompt);
      //console.log(ret[0]);
     
      setInputValue("")
      setText(text+"\n"+"我: "+inputValue+"\n"+ "Solly: "+ret);
      
    
    }
    
  
  };

  return (
    <div style={{display: "flex", height:"100vh", width:"100%", justifyContent: "center", alignItems: "center" }}>
       <Text>{fsmStates.name}</Text>
       <div style={{display: "flex", flexDirection: "column", height:"80%", width:"100%", justifyContent: "center", alignItems: "center" }}>
        <Text>{text}</Text>
        <div>
          <Input placeholder="輸入你的文字" value={inputValue} onChange={handleInputChange} />
          <Button onClick={handleButtonClick}>顯示文字</Button>
        </div>
      </div>
    </div>
  );
};

export default Test;
