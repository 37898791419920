import styled from "styled-components";
import Modal from "styled-react-modal";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fdfaed;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  width: 600px;
  height:600px;
  overflow: hidden;
  position: relative;

`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 17px 17px 17px 17px;
  background-image: url(${(props) => props.url});
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: contain;
`;


export const BtnContainer = styled.div`
  width: 570px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
`;

export const SpeakingBtn = styled.div`
  width: 220px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.BtnColor};
  border: 1.5px solid #3b3b3b;
  border-radius: 38px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 400;
  font-size: 31px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.15em;
  color: #ffffff;
`;

export const MicBtn = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonIcon = styled.div`
  margin: 0px 6px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px; // 設定高度，使其成為一個方形
  background-image: url(${(props) => props.src});
  background-size: contain; // 讓圖片完全覆蓋div
  background-repeat: no-repeat; // 防止圖片重複
  background-position: center; // 讓圖片置中
`;


export const ContinueBtn = styled.div`
  display: ${(props) => (props.speaking ? "none" : "flex")};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 47px;
  background: #ffffff;
  border: 1.5px solid #3b3b3b;
  border-radius: 31px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 35px;
  letter-spacing: 0.15em;
  color: #3b3b3b;
  text-align: center;
  line-height: 47px;
`;

export const GoBackBtn = styled.div`
  display: ${(props) => (props.speaking ? "none" : "flex")};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 47px;
  background: #ffffff;
  border: 1.5px solid #3b3b3b;
  border-radius: 31px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 35px;
  letter-spacing: 0.15em;
  color: #3b3b3b;
  text-align: center;
  line-height: 47px;
`;

export const StyledModal = Modal.styled`
  width: 670px;
  height: 630px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border-radius: 17px;
  border: 1.5px solid #3B3B3B;
  opacity: ${(props) => props.opacity};
  transition : all 0.3s ease-in-out;
  `;

  export const ModalImgContainer = styled.div`
  width: 670px;
  height: 300px;
  overflow: hidden;
  position: relative;
`;


export const ModalImg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 17px 17px 0 0;
  background-image: url(${(props) => props.url});
  background-position: 50% 75%;
`;

export const ModalDescription = styled.div`
  width: 670px;
  height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;


export const ModalBlock = styled.div`
  height: 288px;
  padding-left: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`;

export const ModalTitle = styled.div`
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 52px;
  letter-spacing: 0.2em;
  color: #3b3b3b;
`;
