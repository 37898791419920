import React, { useEffect, useState, useRef} from "react";
import NavBar from "../../public/components/NavBar";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Image,
  SpeakingBtn,
  BtnContainer,
  ContinueBtn,
  ButtonIcon,
  ImageContainer,
} from "./style";
import volume from "../../public/images/volume.png";
import reply from "../../public/images/replay.png";
import Left from "../../public/images/Left.png";
import userpic from "../../public/images/user.png";
import {
  SpeechConfig,
  ResultReason,
} from "microsoft-cognitiveservices-speech-sdk";
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import { description } from "../description";
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import ReactGA from 'react-ga';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";



const Listen = () => {
  const [speaking, setSpeaking] = useState(true);
  const [BtnText, setBtnText] = useState("開始");
  const [BtnColor, setBtnColor] = useState("#3AB795");
  const [BtnIcon, setBtnIcon] = useState(volume);
  const [story, setStory] = useState("");
  const [isShow, setIsShow] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [audioSrc, setAudioSrc] = useState('');
  const [isPlaybackFinished, setIsPlaybackFinished] = useState(false);
  const [currentlevel, setCurrentlevel] = useState('');


  let player
  let url = new URL(window.location.href);
  let searchParams = new URLSearchParams(url.search);
  let index1 = searchParams.get("index1");
  let index2 = searchParams.get("index2");
  let level = parseInt(searchParams.get("level"))-1;
  let voice = searchParams.get("voice");
  let speed = parseFloat(searchParams.get("speed"));
  let stage1 = 1;

  const firebaseConfig = {
    apiKey: "AIzaSyDad4JckQLNMALW3RpKnTq6X3KuPP90Ej0",
    authDomain: "polavoice-test.firebaseapp.com",
    projectId: "polavoice-test",
    storageBucket: "polavoice-test.appspot.com",
    messagingSenderId: "163776593379",
    appId: "1:163776593379:web:fc91cac693491786abb4d6",
    measurementId: "G-GX4XSS66XR"
  };
  // 初始化 Firebase 應用
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const navigate = useNavigate();

  const [arg, setArg] = useState(JSON.parse(decodeURIComponent(searchParams.get("arg"))));
  const audioRef = useRef(null);

  const speechConfig = SpeechConfig.fromSubscription(
    "533f3aacacc74f5cb0f678b05d9f9227",
    "eastus"
  );
  speechConfig.speechSynthesisVoiceName = "zh-TW-HsiaoYuNeural";
  speechConfig.speechSynthesisLanguage = "zh-TW";
  speechConfig.speechSynthesisVoiceSpeed = 0.5;
  speechConfig.speechSynthesisRate = 0.5;
    
  player = new SpeechSDK.SpeakerAudioDestination();
  var audioConfig  = SpeechSDK.AudioConfig.fromSpeakerOutput(player);
  const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);

  useEffect(() => {
    if(level == 0){
      document.title = "層級"+(level+1)+"-"+(parseInt(index1)+1);
      setCurrentlevel('level1');
    }else if(level == 1){
      document.title = "層級"+(level+1)+"-"+(parseInt(index1)-3);
      setCurrentlevel('level2');
    }else if(level == 2){
      document.title = "層級"+(level+1)+"-"+(parseInt(index1)-7);
      setCurrentlevel('level3');
    }
  }, []);
  
  //firebase
  //const [teachData, setTeachData] = useState([]);
  /*
  useEffect(() => {
    const fetchTeachData = async (parentPath, childName) => {
      let documents = [];
  
      // 獲取當前層級的所有文檔快照
      const currentSnapshot = await getDocs(collection(db, ...parentPath));
      for (const docSnapshot of currentSnapshot.docs) {
        const data = docSnapshot.data();
        const docId = docSnapshot.id;
        const docPath = [...parentPath, docId];
  
        // 構造當前文檔的資料和路徑
        const docData = {
          id: docId,
          path: docPath.join('/'),
          data: data,
        };
        // 嘗試獲取子集合的文檔
        const childCollectionRef = collection(db, ...docPath, childName);
        const childSnapshot = await getDocs(childCollectionRef);
        if (!childSnapshot.empty) {
          // 子集合存在，則進一步遞迴獲取子集合中的文檔資料
          const childrenData = await fetchTeachData([...docPath, childName], childName);
          docData.children = childrenData;
        }
        documents.push(docData);
      }
      return documents;
    };
  
    fetchTeachData(['Teach'],'1').then(teachData => {
      setTeachData(teachData);
      console.log(teachData);
      console.log("L"+level);
    }).catch(error => {
      console.error("Error fetching data: ", error);
    });
  }, []);*/
    const [teachData, setTeachData] = useState([]);
  
    useEffect(() => {
      const fetchDataByLevel = async (currentlevel) => {
        let documents = [];
        const levelCollectionRef = collection(db, "Teach", currentlevel, "1");
        const snapshot = await getDocs(levelCollectionRef);
        snapshot.docs.forEach(doc => {
          documents.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        return documents;
      };
  
      fetchDataByLevel(currentlevel)
        .then(teachData => {
          setTeachData(teachData);
          console.log(teachData);
          console.log(currentlevel);
        })
        .catch(error => {
          console.error("Error fetching data: ", error);
        });
    }, [currentlevel]); 

  // 狀態來追踪當前問題的索引，初始為 Q1
  //const groupData = teachData[level]?.children[level == 0 ? index1 : level == 1 ? index1-4 : index1-8]?.data?.description;
  const groupData = teachData[index1]?.data?.description;

  const [user, setUser] = useState(null);
  const [showSignOut, setShowSignOut] = useState(false);
  //是否有登入
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // 使用者已登入，更新狀態
        setUser(user);
        console.log(user);
      } else {
        // 使用者未登入，將狀態設為null並重定向到登入頁面
        setUser(null);
        navigate('/Login'); // 假設你的登入頁面路徑是'/login'
      }
    });
  }, [navigate]);

  // 登出函數
  const handleSignOut = () => {
    signOut(auth).then(() => {
      alert('登出成功！');
      navigate('/Login');
    }).catch((error) => {
      // 處理錯誤
      console.error("登出錯誤", error);
    });
  };

  useEffect(() => {
    const loadImage = async () => {
        const imageModule = await import("../../public/images/level"+(level+1)+"/pic"+(parseInt(index2))+".png");
        setImageUrl(imageModule.default);
    };
    loadImage();
  }, []);

  useEffect(() => {
    console.log(speed)
    console.log(voice)
    console.log("start");
    reset();    
    console.log(groupData)
    setStory(groupData)
  }, []);

  const reset = () => {
    setBtnText("開始");
    setBtnColor("#3AB795");
    setSpeaking(false);
  };

  const handleClick = () => {
    if(groupData == null || groupData == undefined){
      return
    }
    if (BtnText == "繼續" && audioRef.current) {
      audioRef.current.play();
      setSpeaking(true);
      setBtnText("播放中...");
    }else if(BtnText == "播放中..." && audioRef.current) {
      audioRef.current.pause();
      setSpeaking(false);
      setBtnText("繼續");
    }else if(BtnText == "開始" || BtnText == "再聽一次"){
      setBtnText("播放中...");
      setSpeaking(true);
      setBtnColor("#ffb703");
      handleTextToSpeech(groupData);
    }
    if(speaking){
      console.log(player.currentTime)
      //setBtnText("暫停");
      //player.pause();
      return
    }
    
  };

  const calculateValue = () => {
    if (speed >= 0.1 && speed <= 1) {
      // 当speed在0.1到1之间时，使用线性插值计算value
      // y = mx + b 形式，其中m是斜率，b是y轴截距
      var m = (0 - (-90)) / (1 - 0.1); // 计算斜率
      var b = -90 - m * 0.1; // 计算y轴截距
      if(voice == "male" || voice == "female2"){
        return m * speed*0.75 + b +"%";
      }else{
        return m * speed + b +"%";
      }
    } else if (speed > 1 && speed <= 2) {
      // 当speed在1到2之间时，使用另一个线性插值计算value
      var m = (100 - 0) / (2 - 1); // 计算斜率
      var b = 0 - m * 1; // 计算y轴截距
      if(voice == "male" || voice == "female2"){
        return m * speed*0.75 + b +"%";
      }else{
        return m * speed + b +"%";
      }
    } else {
      // 如果speed不在0.1到2的范围内，返回null或者抛出错误
      return null; // 或者 throw new Error('Speed out of range');
    }
  }

  const handleTextToSpeech = () => {

    //const content = story == "" ? text : story
    
    let question = groupData
    console.log(question) // 示例速度值，您需要根據實際情況來設定

    const voiceType = voice == "female" ? 'zh-TW-HsiaoYuNeural' : (voice == "female2" ? "fr-FR-VivienneMultilingualNeural" : 'zh-TW-YunJheNeural'); 
    const speedType = calculateValue()    
    const ssml = `<speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts="http://www.w3.org/2001/mstts" xml:lang='zh-TW'>
      <voice name='${voiceType}'>
      <mstts:silence  type="Leading-exact" value="1300ms" style="excited"/>
        <prosody rate='${speedType}'>${question}</prosody>
      </voice>
    </speak>`;
    synthesizer.speakSsmlAsync(
      ssml,
      (result) => {
        if (result.reason === ResultReason.SynthesizingAudioCompleted) {
          console.log("Speech synthesis succeeded");
          player.mute()
          if(audioSrc == ""){
            const audioBlob = new Blob([result.audioData], { type: 'audio/mp3' });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioSrc(audioUrl);
          }else{
            audioRef.current.play();
          }
        } else {
          console.error("Speech synthesis failed: " + result.errorDetails);
        } 
        synthesizer.close();
      },
      (error) => {
        console.error("An error occurred while synthesizing speech: " + error);
        synthesizer.close();
      }
    );
  };
  synthesizer.synthesizing = function (s, e) {
    var str = `Synthesizing event: \
        \r\n\tAudioData: ${e.result.audioData.byteLength} bytes`;
    //console.log(str);
  };
  synthesizer.visemeReceived = function(s, e) {
    var str = `VisemeReceived event: \
        \r\n\tAudioOffset: ${(e.audioOffset + 5000) / 10000}ms \
        \r\n\tVisemeId: ${e.visemeId}`;
    //console.log(str);
  };
  const onAudioEnd = () => {
    console.log("playback finished");
    setBtnText("再聽一次");
    setBtnColor("#3AB795");
    setSpeaking(false);
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
    }
  };

  /*player.onAudioStart = (_) => {
    console.log("playback started");
    console.log(player.currentTime)
  };

  player.onAudioEnd = (_) => {
    console.log("playback finished");
    console.log(player.currentTime)
    setTimeout(reset, 900);

  };*/

  const handleAudioEnded = () => {
    console.log("Audio playback ended");
    setBtnText("再聽一次");
    setBtnColor("#3AB795");
    setBtnIcon(reply);
    setSpeaking(false);
    setIsShow(true)
  };


  return (
    <Container>
      <NavBar index1={index1} index2={index2}/>
      <div style={{position:'fixed',left:'15px' ,top:'15px'}}>
          {user && (
            <span>
              <img src={userpic} alt='使用者' onClick={() => setShowSignOut(!showSignOut)} style={{width:'35px', height:'35px',cursor: 'pointer'}}/>
              {showSignOut && (
                <div style={{ position:'fixed', left:'15px',marginTop:'10px', backgroundColor:'#e5e5e5', padding:'1% 1%', borderRadius:'10px'}}>
                  <h4 style={{marginBottom:'10px'}}>歡迎：{user.email}登入使用</h4>
                  <button style={{padding:'2px 123px',cursor:'pointer'}} onClick={handleSignOut}>登出</button>
                </div>
              )}
            </span>
          )}
      </div>
      <ImageContainer>
        <Image url={imageUrl} />
      </ImageContainer>
      <audio ref={audioRef} src={audioSrc} controls autoPlay onEnded={onAudioEnd}/>
      <BtnContainer>
        <SpeakingBtn BtnColor={BtnColor} onClick={handleClick}>
          {BtnText}
        </SpeakingBtn>
        <ContinueBtn
          isShow={isShow}
          onClick={() => {
            window.location.href = `/question?level=${level+1}&index1=${index1}&index2=${parseInt(index2)+1}&speed=${speed}&voice=${voice}&stage1=${stage1}`;
          }}
        >
          <ButtonIcon src={Left} width={20}></ButtonIcon>
          繼續
        </ContinueBtn>
      </BtnContainer>
    </Container>
  );
};

export default Listen;
