import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useCurrentUser } from './googleAnalytics';
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';

function PageViewTracker() {
  const location = useLocation();
  const user = useCurrentUser();
  const analytics = getAnalytics();

  useEffect(() => {
    if (user) {
      setUserId(analytics, user.uid);
      /*setUserProperties(analytics, {
        member: user.uid
      });*/
      window.dataLayer.push({
        event: 'pagetrack',
        userId: user.uid,
        userEmail: user.email,
        page_path: location.pathname,
        page_title: document.title
      });
      /*logEvent(analytics, 'custom_login', {
        uid: user.uid,
        email: user.email
      });*/
    } else {
      setUserId(analytics, null);
      //logEvent(analytics, 'custom_logout');
    }

    // 发送自定义页面视图事件
    /*logEvent(analytics, 'custom_page_view', {
      page_path: location.pathname,
      page_title: document.title,
      member: user ? user.uid : '未登入'
    });

    // 发送页面视图事件
    logEvent(analytics, 'page_view', {
        page_path: location.pathname,
        page_title: document.title,
        member: user ? user.uid : '未登入'
      });*/

    console.log("UserID：" + (user ? user.uid : '未登入'));
  }, [location, user, analytics]);

  return null;
}

export default PageViewTracker;
