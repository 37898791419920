import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import './GoogleLogin.css';
import icon from '../../public/images/icon1.png';
import { Helmet } from 'react-helmet';

function SignInWithGoogle() {
  const navigate = useNavigate();

  // 初始化 Firebase 應用
  const firebaseConfig = {
    apiKey: "AIzaSyDad4JckQLNMALW3RpKnTq6X3KuPP90Ej0",
    authDomain: "polavoice-test.firebaseapp.com",
    projectId: "polavoice-test",
    storageBucket: "polavoice-test.appspot.com",
    messagingSenderId: "163776593379",
    appId: "1:163776593379:web:fc91cac693491786abb4d6",
    measurementId: "G-GX4XSS66XR"
  };

  // 初始化 Firebase 应用
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const analytics = getAnalytics(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // 用户已登录，设置用户ID并记录登录事件
        setUserId(analytics, user.uid);
        logEvent(analytics, 'login', {
          method: 'Google'
        });
        navigate('/');
      } else {
        // 用户已登出，记录登出事件并清除用户ID
        logEvent(analytics, 'logout');
        setUserId(analytics, null);
        navigate('/Login');
      }
    });

    // 清理订阅
    return () => unsubscribe();
  }, [auth, analytics, navigate]);

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // Google登入成功，追蹤事件
        console.log(result.user);
        console.log(result.user.uid);
        window.dataLayer.push({
          event: 'login',
          userId: result.user.uid,
          userEmail: result.user.email,
          //loginTime: new Date().toISOString()
        });
        console.log(window.dataLayer);
        navigate('/');
      }).catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="container">
      <Helmet>
        <title>GOOGLE登入</title>
      </Helmet>
      <div className="center">
        <div className="auth">
          <p className="auth-text">登入</p>
        </div>
        <button className="google-login-button" onClick={handleGoogleSignIn}>
          <img src={icon} alt="Google Icon" className="google-icon" />
          <span className="google-login-btn-text">使用Google 登入</span>
        </button>
      </div>
    </div>
  );
}

export default SignInWithGoogle;