import React, { useEffect, useState, useRef} from "react";
import NavBar from "../../public/components/NavBar";
import styled from "styled-components";
import {
  Container,
  Image,
  MicBtn,
  SpeakingBtn,
  BtnContainer,
  ContinueBtn,
  GoBackBtn,
  ButtonIcon,
  ImageContainer,
} from "./style";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import volume from "../../public/images/volume.png";
import reply from "../../public/images/replay.png";
import micUp from "../../public/images/mic1.png";
import micDown from "../../public/images/mic2.png";
import camping from "../../public/images/home/5.jpg";
import beach from "../../public/images/home/5.jpg";
import Loading from "./Loading.jsx";
import loading from "../../public/images/loading.gif";
import getStory from "../../public/components/GenerateMethod.jsx"
import {
  SpeechSynthesizer,
  SpeechConfig,
  ResultReason,
  SpeechRecognizer
} from "microsoft-cognitiveservices-speech-sdk";
import { ModalProvider } from "styled-react-modal";
import { information } from "./string";
import { prompt } from "../string.js";

const PreQuestion = () => {
  const [speaking, setSpeaking] = useState(true);
  const [BtnText, setBtnText] = useState("說話中...");
  const [BtnColor, setBtnColor] = useState("#ffb703");
  const [BtnIcon, setBtnIcon] = useState(volume);
  const [micIcon, setMicIcon] = useState(micUp);

  const [isShow, setIsShow] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [modalInfo, setModalInfo] = useState([]);
  const [imageUrl, setImageUrl] = useState("");

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  let url = new URL(window.location.href);
  let searchParams = new URLSearchParams(url.search);
  let index = searchParams.get("index");
  let level = searchParams.get("level");

  const navigate = useNavigate();
  const audioRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    console.log("start");
    reset();
    handleTextToSpeech();
  }, []);

  useEffect(() => {
    const loadImage = async () => {

      const imageModule = await import("../../public/images/home/"+(parseInt(index)+1)+".jpg");
      setImageUrl(imageModule.default);
 
    };

    loadImage();
  }, []);


  
  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(function(stream) {
        // 使用者允許權限請求，你可以在這裡使用 MediaStream
      })
      .catch(function(err) {
        // 使用者拒絕權限請求或其他錯誤
        navigate(`/`)
      });
  }, []);





  useEffect(() => {
    console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`);
  }, [location]);

  const reset = () => {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContext = new AudioContext();

    // 暫停所有音效
    audioContext.suspend();
    setBtnText("說話中...");
    setBtnColor("#ffb703");
    setBtnIcon(volume);
    setSpeaking(true);
  };

  const handleClick = () => {

    if (!speaking) {
      reset();
      handleTextToSpeech();
    }
  };

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }

  function setModel(image, text) {
    setModalInfo([image, text]);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  const handleTextToSpeech = () => {
    /*
    const speechConfig = SpeechConfig.fromSubscription(
      "4caafccbb4ac4a90a919756a83a6018f",
      "eastus"
    );
    speechConfig.endpointId = "640b9fe2-1d09-41a8-8f5c-acb57590e347";
    //speechConfig.speechSynthesisVoiceName = "HannahNeural";
    //speechConfig.speechSynthesisVoiceName = "KohlerNeural";
    */
  
    //雅文
    const speechConfig = SpeechConfig.fromSubscription(
      "533f3aacacc74f5cb0f678b05d9f9227",
      "eastus"
    );
    speechConfig.speechSynthesisVoiceName = "zh-TW-HsiaoYuNeural";
    //speechConfig.speechSynthesisVoiceSpeed = 0.3;
    const synthesizer = new SpeechSynthesizer(speechConfig);
    let msg = ""


    msg = prompt.level2[index].preQuestion
  
    synthesizer.speakTextAsync(
      msg,
      (result) => {
        if (result.reason === ResultReason.SynthesizingAudioCompleted) {
          console.log("Speech synthesis succeeded");
          playAudio(result.audioData); // 播放合成音频
        } else {
          console.error("Speech synthesis failed: " + result.errorDetails);
        }
        synthesizer.close();
      },
      (error) => {
        console.error("An error occurred while synthesizing speech: " + error);
        synthesizer.close();
      }
    );
  };

  const playAudio = (audioData) => {
    const blob = new Blob([audioData], { type: "audio/wav" });
    const audioUrl = URL.createObjectURL(blob);
    audioRef.current.src = audioUrl;
    audioRef.current.play();
  };

  const handleAudioEnded = () => {
    console.log("Audio playback ended");
    setIsShow(false)
    setSpeaking(false);
  };

  const handleSpeechRecognition = () => {
    const speechConfig = SpeechConfig.fromSubscription(
      "533f3aacacc74f5cb0f678b05d9f9227",
      "eastus"
    );
    speechConfig.speechRecognitionLanguage = "zh-TW"; // Language code for Traditional Chinese (Taiwan)
    const recognizer = new SpeechRecognizer(speechConfig);
    recognizer.recognizeOnceAsync(
      (result) => {
        if (result.reason === ResultReason.RecognizedSpeech) {
          console.log("Speech recognized: " + result.text);
          if(result.text.length > 1){
            toggleModal();
            getStory(index, level, result.text).then(info => {
              navigate(`/listen?index=${index}&arg= ${encodeURIComponent(JSON.stringify(info))}`);
              toggleModal();
              setMicIcon(micUp);
            }).catch(error => {
              setMicIcon(micUp);
              console.error(error);
              toggleModal();
            });
          
            setModel(loading,"故事產生中...")
            //navigate(`/listen?index=${index}&where=${result.text.toString()}`)

          }
          // Handle the recognized text here, e.g., send it to the server or use it in your application
        } else {
          setMicIcon(micUp);
          toggleModal();
          console.error("Speech recognition failed: " + result.errorDetails);
        }
        recognizer.close();
      },
      (error) => {
        toggleModal();
        setMicIcon(micUp);
        console.error("An error occurred during speech recognition: " + error);
        recognizer.close();
      }
    );
  };

  const handleMicDown = () => {
    setBtnText("dddd")
    console.log("Mic button pressed");
    setMicIcon(micDown);
    handleSpeechRecognition();
  };

  const handleMicUp = () => {

    //console.log("Mic button released");
    //setMicIcon(micUp);

    //setMicIcon(micUp);
  };


  return (
    <ModalProvider>
      <Container>
        <NavBar />
        <ImageContainer>
         <Image url={imageUrl} />
        </ImageContainer>
        <BtnContainer>
          {!speaking && (
           <MicBtn
           {...(isMobile ? {
             onTouchStart: handleMicDown,
             onTouchEnd: handleMicUp,
           } : {
             onMouseDown: handleMicDown,
             onMouseUp: handleMicUp,
           })}
           onContextMenu={(e) => { e.preventDefault(); return false; }}
           speaking={speaking}
           style={{
            WebkitTouchCallout: 'none',
            WebkitUserSelect: 'none',
            KhtmlUserSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none',
          }}
         >
           <ButtonIcon src={micIcon} width={100}></ButtonIcon>
         </MicBtn>
          )}
          {isShow && (
            <SpeakingBtn BtnColor={BtnColor} onClick={handleClick}>
            <ButtonIcon src={BtnIcon} width={26}></ButtonIcon>
            {BtnText}
          </SpeakingBtn>
          )}
        
        </BtnContainer>
        <Loading
            isOpen={isOpen}
            afterOpen={afterOpen}
            beforeClose={beforeClose}
            toggleModal={toggleModal}
            opacity={opacity}
            image={modalInfo[0]}
            text={modalInfo[1]}
            index={index}
          ></Loading>
        <audio ref={audioRef} onEnded={handleAudioEnded} muted={true} />
      
      </Container>
    </ModalProvider>
  );
};

export default PreQuestion;
