//const axios = require('axios');
// Firebase 的初始化和 Firestore 的使用也需要轉換為 CommonJS 樣式
// 通常，Firebase SDK 的 CommonJS 導入可能看起來如下所示，但請根據您的具體版本和安裝情況進行調整
import axios from 'axios';

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
const { collection, addDoc } = require('firebase/firestore');

let string;
import('../../components/string.js').then((module) => {
  string = module.default;
}).catch(err => console.error(err));

const firebaseConfig = {
  apiKey: "AIzaSyDad4JckQLNMALW3RpKnTq6X3KuPP90Ej0",
  authDomain: "polavoice-test.firebaseapp.com",
  projectId: "polavoice-test",
  storageBucket: "polavoice-test.appspot.com",
  messagingSenderId: "163776593379",
  appId: "1:163776593379:web:fc91cac693491786abb4d6",
  measurementId: "G-GX4XSS66XR"
};
// 初始化 Firebase 應用
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const getStory = (index, level, where) => {
  return new Promise(async (resolve, reject) => {
    let msg1 = ""
    let msg2 = ""
    let msg3 = ""
    let msg4 = ""
    let msg5 = ""
    if(level == 1){
      
      msg1 = prompt.level1[index].prompt
      msg2 = prompt.level1[index].role1+"並且錯誤答案和正確答案不能太相似，不要每次的答案都一樣"
      msg3 = prompt.level1[index].role2+"例如:"+prompt.level1[index].example1+"例如："+prompt.level1[index].example2
      msg4 = "請幫我產生新的描述句以及答案，格式要完全符合上述所說的規則。"
    }else if(level == 2){
      
      msg1 = prompt.level2[index].prompt
      msg2 = prompt.level2[index].role1+"並且錯誤答案和正確答案不能太相似，不要每次的答案都一樣"
      msg3 = prompt.level2[index].role2+"例如:"+prompt.level2[index].example1+"例如："+prompt.level2[index].example2
      msg4 = "請幫我產生新的旁白並且地點換成"+where+"以及新的描述句跟答案，格式要完全符合上述所說的規則"
      msg5 = "如果"+where+"不是合理地點"+"請幫我隨意使用一個地點。"
    }

    let stories = [];
    
    try {
      let info = {}
      for(let i=0;i<5;i++){
        if(description != ""){
          description = info.description;
        }
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4",
          messages: [
            { role: "system", content: "" },
            { role: "user", content: msg1},
            { role: "user", content: msg2},
            { role: "user", content: msg3},
            { role: "user", content: msg4},
            { role: "user", content: msg5},
    
          ],
          "temperature": 0.8

        },
        {
          headers: {
            Authorization:
              "Bearer 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",
            "Content-Type": "application/json",
          },
        }
      );
      
      const ret = response.data.choices[0].message.content;
      console.log("haha1")
      console.log(ret)
  
      //擷取訊息
      var description = ""
      if(level == 1){
        description = msg1.split("描述句：")[0].split("旁白：")[1].trim();
      }else if(level == 2){
        description = ret.split("描述句：")[0].split("旁白：")[1].trim();
      }

      var question = ret.split("正確答案：")[0].split("描述句：")[1].trim();
      if(question.split("幫我產生").length==2){
        question = question.split("幫我產生")[0]

      }

      var correctAnswer = ret.split("錯誤答案1：")[0].split("正確答案：")[1].trim();
      var wrongAnswer1 = ret.split("錯誤答案1：")[1].split("錯誤答案2：")[0].trim();
      var wrongAnswer2 = ret.split("錯誤答案2：")[1].split("錯誤答案3：")[0].trim();
      var wrongAnswer3 = ret.split("錯誤答案3：")[1].split("錯誤答案4：")[0].trim();
   
      //console.log(desc)
      //var correctAnswer = ret.split("錯誤答案1的DALL描述句：")[0].split("正確答案的DALL描述句：")[1].trim();
      //var wrongAnswer1 = ret.split("錯誤答案1的DALL描述句：")[1].split("錯誤答案2的DALL描述句：")[0].trim();
      //var wrongAnswer2 = ret.split("錯誤答案2的DALL描述句：")[1].split("錯誤答案3的DALL描述句：")[0].trim();
      //var wrongAnswer3 = ret.split("錯誤答案3的DALL描述句：")[1].trim();

      //console.log(correctAnswer);
      //console.log(wrongAnswer1);
      //console.log(wrongAnswer2);
      //console.log(wrongAnswer3);
      //toggleModal()
      info = {
        description,
        question,
        correctAnswer,
        wrongAnswer1,
        wrongAnswer2,
        wrongAnswer3,
      };

      console.log("haha2")
      console.log(info.description)
      console.log(info.question)
      console.log(info.correctAnswer)
      console.log(info.wrongAnswer1)
      console.log(info.wrongAnswer2)
      console.log(info.wrongAnswer3)
      stories.push(info);
    }
      
      await addDoc(collection(db, 'AIGenerate','level1','UnRead'), {
        ...stories,
        //createdAt: firebase.firestore.FieldValue.serverTimestamp(), // 使用伺服器的時間戳記
      }).then(() => {
        console.log('Story saved successfully');
        resolve(info);
      })
      .catch((error) => {
        console.error('Error saving story:', error);
        reject(error); // 如果有錯誤，拒絕Promise
      });
    } catch (error) {
      reject("Reject"+error);
      console.error(error);
    }
  });
};
export default getStory;
{/*
export const getPicPrompt = (obj) => {
  return new Promise(async (resolve, reject) => {
    let msg1 = "Give you a noun, you describe it with the following rules, First sentence: Category (It is a kind of animal) Second sentence: Name (Squirrel) Third sentence: Description of appearance (It has a lot of fur all over its body) Fourth sentence: Unique characteristics of this item (Its tail is big and fluffy) For example, it is a kind of animal, squirrel, it has a lot of fur all over its body, and it has a big fluffy tail."
    let msg2 = "Now the noun is: "+obj+", what is its description?"
    let msg3 = "Just give me a descriptive sentence, without any other text or people involved."
    let msg4 = "use english please."

    
     //msg1 = prompt.example.prompt1 + obj + prompt.example.prompt2
 
 

      
   
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4",
          messages: [
            { role: "system", content: "你是產生Stable Diffusion prompt的專家" },
            { role: "user", content: msg1},
            { role: "user", content: msg2},
            { role: "user", content: msg3},
            { role: "user", content: msg4},
          ],
        },
        {
          headers: {
            Authorization:
              "Bearer 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",
            "Content-Type": "application/json",
          },
        }
      );

      const ret = response.data.choices[0].message.content;
      //console.log("sss")
      //console.log(ret)
      //console.log(ret.split("Stable Diffusion Prompt:"))
      var picPrompt = "Painting."+"Pixar-style."+ret
    
    
      //console.log("ffff")
      //console.log(picPrompt)
      
    
 
      resolve(picPrompt);
    } catch (error) {
      reject(error);
    }
  });
};

export const genPic = async(msg) =>{
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "key": "bEln5bsnuWQjvJsogLiDp8iPZjnXYmAigghOFhSZtjTQ0NQjcR21gZ60Ml9S",
    "prompt": msg,
    "negative_prompt": null,
    "width": "512",
    "height": "512",
    "samples": "1",
    "num_inference_steps": "20",
    "seed": null,
    "guidance_scale": 7,
    "safety_checker": "yes",
    "multi_lingual": "no",
    "panorama": "no",
    "self_attention": "no",
    "upscale": "no",
    "embeddings_model": null,
    "webhook": null,
    "track_id": null
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  

  const response =  await fetch("https://stablediffusionapi.com/api/v3/text2img", requestOptions)
    .then(response => response.text())
    .then(result => {
      //console.log(result)
      //console.log("haha")
      
      const jsonResult = JSON.parse(result);
      console.log(jsonResult);

      if(jsonResult.status == "error"){
        console.log("error")
        //console.log(jsonResult.status);
        return ""
      }else{
        //console.log(jsonResult.output[0]);
        let output = jsonResult.output[0]
        //setSrc(output)
        return output
      }
     
    })
    .catch(error => {
      console.log('error', error)
      return "" 
    });
   return response
} 
*/}


