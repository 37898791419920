import styled from "styled-components";
import Modal from "styled-react-modal";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fdfaed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonIcon = styled.img`
  margin: 0px 6px;
  width: ${(props) => props.width};
  src: url(${(props) => props.src});
`;


export const Nav = styled.div`
  width: 100vw;
  height: 12vh;
  background: #ffffff;
  border-bottom: 1px solid black;
`;

export const Content = styled.div`
  box-sizing: border-box;
  width: 1180px;
  height: 88vh;
  padding: 25px 73px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.span`
  font-family: Yuanti SC;
  font-size: 50px;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: 0.15em;
  color: #3ab795;
`;

export const Group = styled.div`
  box-sizing: border-box;
  height: 80vh;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Block = styled.div`
  box-sizing: border-box;
  width: 210px;
  height: 210px;
  margin: 0px 20px;
  background: white;
  border-radius: 15px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
}
`;

export const BlockImg = styled.img`
  width: 190px;
  height: 150px;
  margin: 7px;
  object-fit: cover;
  border-radius: 15px;
  border: 1px solid black;
  background-image: url(${(props) => props.url});
  background-size: cover;
  object-fit: cover;
  object-position: 
  width: 100%;
  height: 100%;
`;

export const BlockTitle = styled.span`
  width: 190px;
  margin: 0px 7px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.15em;
  color: #3b3b3b;
  margin-bottom: 10px;
`;

export const StyledModal = Modal.styled`
  width: 670px;
  height: 630px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border-radius: 17px;
  border: 1.5px solid #3B3B3B;
  opacity: ${(props) => props.opacity};
  transition : all 0.3s ease-in-out;
  `;

export const ModalImgContainer = styled.div`
  width: 670px;
  height: 300px;
  overflow: hidden;
  position: relative;
`;

export const ModalImg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 17px 17px 0 0;
  background-image: url(${(props) => props.url});
  background-position: 50% 75%;
`;

export const ModalDescription = styled.div`
  width: 670px;
  height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ModalBlock = styled.div`
  height: 288px;
  padding-left: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`;

export const ModalTitle = styled.div`
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 52px;
  letter-spacing: 0.2em;
  color: #3b3b3b;
`;

export const ModalScheduleBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ModalScheduleText = styled.div`
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 18px;
  letter-spacing: 0.15em;
  color: #7e7e7e;
`;

export const ModalScheduleBar = styled.div`
  height: 13px;
  width: 455px;
  border-radius: 8px;
  background: #e8e8e8;
  border: 1.5px solid #3b3b3b;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-left: 14px;
`;
export const ModalScheduleIcon = styled.div`
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalLine = styled.div`
  width: 570px;
  border-top: 2px solid #3b3b3b;
`;

export const ModalText = styled.div`
  width: 590px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  letter-spacing: 0.15em;
  color: #7e7e7e;
  padding-bottom: 10px;
`;

export const ModalBtnGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ModalBtn = styled.button`
  width: auto;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-right: -1px;
  background-color: ${(props) => props.color};
  color: white;
  font-size: 25px;
  cursor: pointer;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 900;
  line-height: 40px;
  text-align: center;
  padding: 10px;
  letter-spacing: 0.2rem;
  border-bottom-left-radius: ${(props) => (props.wrapL ? "16px" : "0px")};
  border-bottom-right-radius: ${(props) => (props.wrapR ? "16px" : "0px")};
`;

export const ModalBtnIcon = styled.img`
  src: url(${(props) => props.src});
  width: 28px;
  margin-right: 5px;
`;
