import styled from "styled-components";

export const Nav = styled.div`
  width: 100%;
  height: 12vh;
  background: #fdfaed;
  display: flex;
  flex-direction: row;
  align-items: flexx-start;
  justify-content: flex-end;
`;

export const NavBtn = styled.div`
  /* width: 10px; */
  height: 60%;
  width:100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 9px;
  margin-right: 20px;
  margin-top: 10px;
  background: white;
  text-align: center;
  line-height: 40px;
  border: 1px solid #3b3b3b;
  border-radius: 27px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.15em;
  color: #3b3b3b;

  @media (max-width: 950px) {
    width:70px;
    height:50%;
    font-size:14px;
  }
`;

export const Folder = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px;
  background: white;
  text-align: center;
  line-height: 45px;
  border: 1px solid #3b3b3b;
  border-radius: 50%;
`;

export const NavIcon = styled.img`
  width: ${(props) => props.width};
  cursor: pointer;
  margin: 0px 6px;
  src: url(${(props) => props.src});
`;

export const Setting = styled.div`
  width:30%;
  position: fixed; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000; 
  `;

export const CloseButton = styled.button`
  position: absolute;
  top: -25px;
  right: 10px;
  border: none;
  background: none;
  color:white;
  font-size:22px;
`;

export const RangeInput = styled.input.attrs({ type: 'range' })`
  width: 55%;
  margin: 10px 0;
`;

export const Title = styled.h2`
  margin-top: 0;
  padding-bottom: 10px;
  border-bottom:1px solid black;
  color: #333;
  text-align:center;
  margin:20px;
`;

export const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 20px;
`;

export const RadioLabel = styled.label`
  margin-right:4%;
  font-size: 20px;
  width:25%;
`;

export const RadioButton = styled.input`
  margin-right: 5%;
  width:15px;
  height:15px;
`;

export const RangeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 20px;
`;

export const SpeedDisplay = styled.span`
  margin-left: 12px;
  color:green;
  font-size:16px;
  font-weight:bold;
  margin-bottom:5px;
`;

export const Innertitle = styled.h2`
  width:25%;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  border-top:1px solid black;
`;

export const SaveButton = styled.button`
  text-decoration: none;
  background: none;
  border:none;
  border-right: 1px solid black;
  width:50%;
  padding:10px;
  font-size:18px;
`;

export const CancelButton = styled.button`
  text-decoration: none;
  background: none;
  border: none;
  width:50%;
  padding:10px;
  font-size:18px;
`;

export const Overlay = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); // 半透明的黑色背景
  z-index: 10;
`;

//pic setting
export const Picgroup = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 20px;
`;