import styled from "styled-components";
import Modal from "styled-react-modal";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fdfaed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonIcon = styled.img`
  margin: 0px 40px 0px 10px;

  width: ${(props) => props.width};
  src: url(${(props) => props.src});


`;

export const BtnView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 380px;
  height: 70px;
  margin-left:20px;
  margin-right:20px;
  background: ${(props) => props.color};
  border: 1px solid #3b3b3b;
  border-radius: 20px;
  font-family: "Yuanti SC";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.15em;
  color: #ffffff;
  text-align: center;
  line-height: 47px;
`;

export const Title = styled.span`
  font-family: Yuanti SC;
  font-size: 50px;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: 0.15em;
  color: #3ab795;
`;

export const BtnContainer = styled.div`
  width: 870px;
  height: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
`;






