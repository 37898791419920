import React from "react";
import {
  StyledModal,
  ModalImgContainer,
  ModalImg,
  ModalDescription,
  ModalTitle,
  ModalBlock,

} from "./style.js";
import { useNavigate } from "react-router-dom";

const Looading = ({
  isOpen,
  afterOpen,
  beforeClose,
  toggleModal,
  opacity,
  image,
  text,
}) => {
  let navigate = useNavigate();

  return (
    <StyledModal
      isOpen={isOpen}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      //onBackgroundClick={toggleModal}
      onEscapeKeydown={toggleModal}
      opacity={opacity}
      backgroundProps={{ opacity }}
    >
      <ModalImgContainer>
        <ModalImg url={image} />
      </ModalImgContainer>
      <ModalDescription>
        <ModalBlock>
          <ModalTitle>{text}</ModalTitle>
         
         
        </ModalBlock>
        
      </ModalDescription>
    </StyledModal>
  );
};

export default Looading;
